import {inject} from 'vue'
import { useRouter } from 'vue-router';
import { ElMessage,ElMessageBox } from 'element-plus';

export default {
  props: {
  info:{type:Object,default:()=>{
    return {img:"1.png"}
  }}
},
  setup(__props) {

const props = __props

const userInfo = JSON.parse(localStorage.getItem("user_info"))
const loginShow = inject('login_show')
const token = localStorage.getItem('token')
const router = useRouter()

// 开户
const open = ()=>{
  if(!token){
    loginShow()
    ElMessage.warning('您当前尚未登录，请先登录~')
    return
  }
  if(userInfo.company_attest === 1&&userInfo.withdraw_attest === 2){
    return ElMessageBox.alert('您的身份认证和提现认证已经通过了喔~','提示',{confirmButtonText: '好的',})
  }
  const path = userInfo.company_attest === 1?'/user/person/open':'/user/basis'
  window.open(router.resolve({path}).href, '_blank');
}

return (_ctx, _cache) => {
  return null
}
}

}