// 获取是否符合联盟商的类型
const getUnionBol = (unionList,typeList)=>{
  return typeList.some(item=>unionList.includes(item.toString()))
}
// 下单限制
export const orderLimit = (item, unionList = []) => {
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  // 4.采购 2.卖货 5.预订 6.促销 7.新客
  if (userInfo.id == item.userId) return false  // 自己发的单可以看
  if (!unionList.length && [4, 7].includes(item.type)) {
    if (item.type == 4) return "您不是联盟商家"
    if (item.type == 7) return "您不是促销商家"
  }
  if(userInfo.role_id === 1 && [4,7].includes(item.type)){
    return "您是买家，不能接该笔订单"
  }
  if(userInfo.role_id === 2 && [2,6].includes(item.type)){
    return "您是卖家，不能接该笔订单"
  }
  if (!item.exclusive_user_id && !item.exclusive_company) {
    // if (item.type == 7 && !getUnionBol(unionList,[5,6])) return "您不是新联盟商家"
    if (item.type == 4 && !getUnionBol(unionList,[2,5])) return "您不是联盟商家"
    return false
  }
  if (item.exclusive_company && item.exclusive_company != userInfo.company_id) return "您不是该笔订单的指定公司"
  if (item.exclusive_user_id && item.exclusive_user_id != userInfo.id) return "您不是该笔订单的指定人"
  return false
}

// 不是指定人或者自己的订单就隐藏信息
export const hiddenInfo = (item, info) => {
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  if (userInfo && userInfo.id == item.userId) return info  //自己发的单可以看
  // 没登录时，指定人和公司的情况
  if (!userInfo && (item.exclusive_user_id || item.exclusive_company)) return "***"
  // 不是指定公司
  if (item.exclusive_company && item.exclusive_company != userInfo.company_id) return "***"
  // 不是指定人或者自己发的单或者已成交的单
  if (item.exclusive_user_id && item.exclusive_user_id !== userInfo.id && userInfo.id !== item.userId && !item.deal) return "***"
  return info
}

// 获取指定信息：返回文字和图标
export const getDesigneeInfo = (type,item)=>{
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  // type:1.icon,2.文字说明
  if(type == 1){
    return (userInfo.id == item.userId&&item.exclusive_user_id || userInfo.id == item.exclusive_user_id || userInfo.company_id == item.exclusive_company&&!item.exclusive_user_id) ? "&#xe7a1; ":"&#xe792; "
  }
  if(type == 2){
    if(userInfo.id == item.userId&&item.exclusive_user_id) return `您发给${item.goods_phone}的指定单`
    if(userInfo.id == item.userId&&item.exclusive_company) return `您发给${item.exclusive_company_name}的指定单`
    if(userInfo.id == item.exclusive_user_id) return (item.userName+"指定给您的订单")
    if(userInfo.company_id == item.exclusive_company) return (item.userName+"指定给您公司的订单")
    if(item.exclusive_user_id) return (item.userName+"发给其他人的指定单")
    if(item.exclusive_company) return (item.userName+"发给其他公司的指定单")
  }
}
// 进入详情页的限制
export const enterDetailLimit = (item)=>{
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  if(userInfo&&userInfo.id == item.userId) return false  //自己发的单
  if(item.exclusive_company&&item.exclusive_company != userInfo.company_id)return "您不是该笔订单的指定公司"
  if(item.exclusive_user_id&&item.exclusive_user_id != userInfo.id)return "您不是该笔订单的指定人"
  return false
}

// 获取vip对应的样式
export const getVipStyle = (vip)=>{
  if(vip.includes('普通'))return 'vip_type1'
  if(vip.includes('体验'))return 'vip_type2'
  if(vip.includes('标准'))return 'vip_type3'
  if(vip.includes('高级'))return 'vip_type4'
  if(vip.includes('超级'))return 'vip_type5'
  return 'vip_type1'
}