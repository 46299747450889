import { reactive,inject } from "vue"
import { $getVipRebateData } from '@/api/modules/vip'
import { ElMessage,ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import { Refresh } from '@element-plus/icons-vue'

export default {
  setup(__props) {

const LoginShow = inject('login_show')
const userInfo = JSON.parse(localStorage.getItem('user_info'))
const route = useRoute()
const info = reactive({
  token:localStorage.getItem('token'),
  vipRebateData:'',//vip数据
})
// 获取VIP补贴数据
async function getVipRebateData(){
  const {status,data} = await $getVipRebateData({token:info.token})
  info.vipRebateData = data
}
info.token&&getVipRebateData()
// 刷新VIP补贴数据
const refreshVipRebateData = ()=>{
  info.vipRebateData = ''
  info.token&&getVipRebateData()
  ElMessage.success({message:'刷新成功'})
}

return (_ctx, _cache) => {
  return null
}
}

}